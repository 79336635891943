@import '~@ng-select/ng-select/themes/default.theme.css';
@import '~bootstrap/scss/bootstrap';
@import '~bs-stepper/dist/css/bs-stepper.min.css';

@font-face {
  font-family: 'Montserrat';
  src: url('assets/font/Montserrat-Regular.otf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('assets/font/Montserrat-Bold.otf');
  font-weight: 700;
  font-style: normal;
}

html * {
  font-family: 'Montserrat', sans-serif;
  color: #454a53;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

h1 {
  font-size: 3.5rem;
  text-align: center;
}

body a:hover {
  text-decoration: none;
}

ngx-spinner div {
  &.loading-text,
  div.la-ball-pulse div {
    font-size: 2rem;
    color: #fff;
  }
}

.row {
  margin-right: 0;
  margin-left: 0;
}

.img-fluid {
  width: 10%;
}

/**
 * This fills the remaining space, by using flexbox.
 * Every toolbar row uses a flexbox row layout.
 */
.fill-space {
  flex: 1 1 auto;
}

.toolbar-icon {
  padding: 0 14px;
}

.top-space {
  margin: 30px 0;
}

div.header-row {
  height: 100%;
  align-items: center;
}

div#content-wrapper {
  display: block;
  width: 100vw;
  height: 100%;
  margin: 0;
  overflow-y: auto;
}

div.results-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

div.results-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/**
 * Padding for content wrapper with left vertical panel.
 */

.content-with-left-panel {
  padding-right: 6rem !important;
  padding-left: 12rem !important;
}

/**
 * Padding for content wrapper - small.
 */

.padded-content-1 {
  padding-right: 6rem !important;
  padding-left: 6rem !important;
}

/**
 * Padding for content wrapper - big.
 */

.padded-content-2 {
  padding-right: 8rem !important;
  padding-left: 8rem !important;
}

.ng-sidebar {
  width: 28vw;
}

.ng-sidebar__content {
  /** Set application content to whole screen **/
  > div {
    overflow: auto;
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    /** Set all in-app pages displayable with header **/
    > app-dashboard,
    > app-children-list,
    > app-list,
    > app-basic-task,
    > app-question-note,
    > app-text-note,
    > app-result {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }
}

.table {
  td,
  th {
    vertical-align: middle;
    padding: 0.5rem !important;
  }
}

/* iSophi buttons start: */
.ibtn {
  cursor: pointer;
  text-align: center;
  border-radius: 2rem;
  font-size: 1.1rem;
  padding: 1rem 2rem;
  text-transform: uppercase;
  font-weight: bold;
  border: 2px solid #454a53;
  min-width: 8rem;

  &:focus {
    outline: none;
    -webkit-box-shadow: 0 0 2px 2px #4d90fe;
    box-shadow: 0 0 2px 2px #4d90fe;
  }

  &:disabled,
  &:disabled:hover {
    background-color: #afafaf;
    border-color: #afafaf;
  }

  .fa {
    color: #ffffff !important;
  }
}

.ibtn-primary {
  background-color: #454a53;
  color: #ffffff;

  &:hover {
    background-color: #5b6069;
    border-color: #5b6069;
  }
}

.ibtn-secondary {
  background-color: inherit;
  color: #454a53;

  &:hover {
    background-color: #454a53;
    color: #ffffff;
  }

  &:disabled,
  &.no-license {
    background-color: inherit;
    border-color: #afafaf;
    color: #afafaf !important;
  }

  .fa {
    color: #454a53 !important;
  }
}

.ibtn-attention {
  border: none;
  background-color: #fa8903;
  color: #ffffff !important;
}

.ibtn-small {
  font-size: 1rem;
  padding: 0.6rem 1.3rem;
}

.ibtn-large {
  font-size: 1.5rem;
  padding: 1.5rem 2.5rem;
}
/* END of iSophi buttons */

/**
 * LICENSES
 * ... locking/hiding the elements
 */

button.no-license {
  @extend .disabled;
}

a.no-license {
  @extend .disabled;
}

/**
 * OTHER
 */

p {
  font-size: 1.15em;
  display: initial;
}

.text-red {
  color: red;
}

.uppercase {
  text-transform: uppercase;
}

.tooltip {
  min-width: 20rem;
  margin-left: 1rem;
  max-width: none;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  background: #ffffff;
}

.tooltip-header {
  padding: 15px 0 15px 22px;
}

.tooltip {
  opacity: 1 !important;
  padding: 0 !important;
}

.tooltip-inner {
  max-width: none;
  background-color: #ffffff;
  color: initial;
  text-align: left;
  padding: 0;
}

.tooltip .arrow {
  left: -7px;

  &::before {
    left: 0;
    border-right-color: rgba(0, 0, 0, 0.2);
  }
}

.buttons-down {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  text-align: center;
  background-color: #fff;
  height: 6rem;

  &.show-alert {
    height: 10rem;
  }
}

.navigation-buttons {
  .alert {
    margin-bottom: 0.65rem;
  }
}

.modal-dialog {
  max-width: 70% !important;
}

ngb-modal-window {
  &.modal {
    z-index: 9999;
  }
}

.ngb-dp-day {
  width: 2.2rem !important;
  height: 1.8rem !important;
}

.ngb-dp-weekday {
  width: 2.2rem !important;
  height: 2rem !important;
}

.ngb-dp-day > div {
  width: 100% !important;
  height: 100% !important;
  padding: 0rem;
}

.ngb-dp-week {
  padding-top: 0.8rem;
}

.custom-select {
  font-size: 1.2rem !important;
  height: 2.5rem !important;
}

ngb-datepicker {
  * {
    font-size: 1.1rem;
  }

  option {
    font-size: 0.65rem;
  }
}

.date-input {
  border-radius: 0.45rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input-group-append {
  .btn-outline-secondary {
    height: 100%;

    .fa-calendar {
      font-size: 2em;
    }
  }
}

.btn-outline-secondary:hover {
  color: #fff !important;
  background-color: #454a53 !important;
  border-color: #454a53 !important;
}

.btn-outline-secondary:hover i {
  color: #fff !important;
}

.cdk-overlay-pane {
  position: absolute !important;
  top: 20px;
}

.breathing {
  -webkit-animation: breathing 1s ease-out infinite normal;
  animation: breathing 1s ease-out infinite normal;
}

@-webkit-keyframes breathing {
  0% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  25% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  60% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@keyframes breathing {
  0% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  25% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }

  60% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }

  100% {
    -webkit-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);
  }
}

.no-child {
  position: initial !important;
  display: inline-block;
}

form {
  .form-group {
    font-size: 1.2rem;
  }

  .form-control {
    font-size: 1.5rem;
  }
}

.head h1 {
  font-size: 4em;
  padding-top: 1.5rem;
  text-align: center;
}

.no-background {
  background: none !important;
}

.forgotten-password {
  text-decoration: underline !important;
}

.ng-input-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  label {
    color: #454a53;
    padding: 0;
    display: flex;
    align-items: center;
    font-size: 1.2rem;

    &.btn:focus,
    &.btn.focus {
      outline: none;
      box-shadow: none;
    }

    input[type='radio'],
    input[type='checkbox'] {
      all: unset; /* this shorthand includes resetting appearance to none */
      width: 2rem;
      min-width: 2rem;
      height: 2rem;
      min-height: 2rem;
      position: relative;
      margin-right: 8px;
      display: inline-block;
      background-color: initial;
      cursor: pointer;
      border: 1px solid gray;
    }

    input[type='radio'] {
      border-radius: 50%;
    }

    input[type='radio']:checked {
      background: radial-gradient(rgba(224, 147, 126) 40%, transparent 50%);
      border: 2px solid rgba(224, 147, 126);
    }

    input[type='checkbox']:checked {
      background: rgba(224, 147, 126);
      border: 1px solid rgba(224, 147, 126);
    }

    input[type='checkbox']:checked::after {
      position: absolute;
      display: block;
      content: '';
      left: 0.76rem;
      top: 0.2rem;
      width: 0.67rem;
      height: 1.47rem;
      border: solid white;
      border-width: 0 0.3rem 0.3rem 0;
      transform: rotate(45deg);
    }

    input[type='radio']:focus-visible,
    input[type='checkbox']:focus-visible {
      outline: auto;
    }
  }
}

.bs-tooltip-auto[x-placement^='right'] .arrow,
.bs-tooltip-right .arrow {
  width: 0.8rem;
}

.tooltip .arrow {
  left: -0.9rem;
}

.bs-tooltip-auto[x-placement^='right'] .arrow::before,
.bs-tooltip-right .arrow::before {
  border-width: 0.7rem 0.7rem 0.7rem 0;
}

@media only screen and (max-width: 1920px) {
  html {
    font-size: 15px;
  }
}

@media only screen and (max-width: 1700px) {
  html {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1600px) {
  html {
    font-size: 13px;
  }
}

@media only screen and (max-width: 1500px) {
  html {
    font-size: 12px;
  }
}

@media only screen and (max-width: 1280px) {
  html {
    font-size: 11px;
  }
}

@media only screen and (max-width: 1000px) {
  html {
    font-size: 10px;
  }
}

@media only screen and (max-width: 900px) {
  html {
    font-size: 9px;
  }
}

@media only screen and (max-width: 800px) {
  html {
    font-size: 8px;
  }
}

@media only screen and (max-width: 700px) {
  html {
    font-size: 7px;
  }
}

@media only screen and (max-width: 600px) {
  html {
    font-size: 6px;
  }
  .content-with-left-panel {
    padding-right: 2rem !important;
    padding-left: 7rem !important;
  }
}

@media only screen and (max-width: 440px) {
  .buttons-down {
    &.show-alert {
      height: 12rem;
    }
  }
}

/** General style helpers. */

.text-underline {
  text-decoration: underline !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.bold {
  font-weight: bold;
}

.child-name {
  font-size: 1.4rem;
}

/** END of General style helpers. */

/* Modal window styling: */

h1#modal-basic-title {
  font-size: 1.5rem;
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  text-align: center;
}

.modal-header {
  padding: 2rem 2rem 1rem 2rem;
}

.modal-body {
  padding: 2rem;
}

.modal-body ngb-alert:last-child {
  margin-bottom: 0;
}

.modal-footer {
  padding: 1rem 2rem 2rem 2rem;
}

.modal-content {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.modal-header h5.modal-title {
  font-size: 1.5rem;
}

.modal-header button.close img {
  height: 1.5rem;
  width: 1.5rem;
}

/* END of Modal window styling */
